// import React from 'react';
// import logo from './logo.svg';
import './App.css';
// import Header from "./components/Header";
import { Analytics } from '@vercel/analytics/react';

function App() {
  return (
    // <></>
    // <Header></Header>
    <Analytics />
  );
}

export default App;
